.doc-container {
    display: table;
    width: 100%;
    border-collapse: collapse;
}

.row-cell {
    display: table-row;
    word-break:break-word;
}

.cell {
    display: table-cell;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    white-space: pre-wrap;
    word-break: break-word;
}

.arrow-button {
    margin-right: 2px;
    margin-left: 2px;
    width: 1.12vw;
    height: 1.12vw;
    border-radius: 50%;
    background-color: white;
}

.column-resize {
    /* Displayed at the right side of column */
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    cursor: col-resize;

    user-select: none;
}