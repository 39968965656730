.db-grid-min-width {
  min-width: 420px;
}

.db-grid-width {
  width: 33.333333%;
}

.db-grid-width-50 {
  width: 50%;
}

@media screen and (max-width: $mobilebp) {
  .db-mb {
    margin-bottom: 16px;
  }
  .db-grid-min-width {
    min-width: 100%;
  }
  .db-grid-width {
    width: 100%;
  }
}

@media screen and (max-width: $largebp) and (min-width: $mobilebp) {
  .db-grid-min-width {
    min-width: 280px;
  }
}
