.doc-build-toast {
    position: fixed;
    transform: translate(-50%, -0%);
    padding: 8px;
    padding-left: 12px;
    padding-right: 12px;
    left: 50%;
    font-size: 14px;
    font-weight: 500;
    color: white;
    z-index: 9999;
    border-radius: 6px;
    box-shadow: 0px 0px 10px rgba(0, 0, 20, 0.15);
    animation-fill-mode: forwards;
    max-width: 400px;
}
.doc-build-toast-top {
    top: 15px;
    animation: SlideTopDown 0.4s;
}
.doc-build-toast-bottom {
    bottom: 20px;
    animation: SlideBottomUp 0.4s;
}
.doc-build-toast-success {
    background-color: rgb(83, 168, 64);
}
.doc-build-toast-failure {
    background-color: rgb(193, 60, 55);
}
.doc-build-toast-default {
    background-color: rgb(40, 40, 40);
}
.doc-build-toast-warning {
    background-color: #ffba27;
}
@keyframes SlideTopDown {
    0% {
        margin-top: -50px;
    }
    100% {
        margin-top: 0px;
    }
}
@keyframes SlideBottomUp {
    0% {
        margin-bottom: -30px;
    }
    100% {
        margin-bottom: 0px;
    }
}
