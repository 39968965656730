* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.yodlee-refresh-iframe {
  width: 100%;
  height: 70vh;
  border: none;
}

.border-color-select {
  border-color: rgb(204, 204, 204);
}

.background-color-select {
  background-color: rgb(242, 242, 242);
}

.dashboard-card {
  width: 32%;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-right: 1%;
}

.bg-app {
  background-color: var(--books-color);
}

.bg-button {
  background-color: var(--btn-bg-color);
}

div.dk-bg-button,
button.dk-bg-button {
  background-color: var(--btn-bg-color);
}

.text-app-color {
  color: var(--books-color);
}

.text-bg-button-color {
  color: var(--btn-bg-color);
}

.hide-scroll-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.dk-kanban {
  user-select: none;
  overflow: scroll !important;
  scroll-behavior: smooth;
}
.dk-kanban > .dk-kanban-stage-wrapper {
  user-select: none !important;
  /* background: #e6e6ee; */
}
.dk-kanban
  > .dk-kanban-stage-wrapper
  > .dk-kanban-card-wrapper
  > .dk-kanban-card {
  transition: transform 0.15s ease;
}
.dk-kanban
  > .dk-kanban-stage-wrapper
  > .dk-kanban-card-wrapper
  > .dk-kanban-card
  > .dk-kanban-default-card {
  background-color: #ffffff;
}
.cursor-grabbing {
  cursor: grabbing;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.cursor-move {
  cursor: move;
}
.d-flex {
  display: flex;
}
.stage-toggle-button:hover {
  background-color: #5a64d7;
}
/* Style the tab */
.tab {
  overflow: hidden;
}

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

.tab .tablinks:hover {
  color: #5a64d7;
}

.tab .tablinks.active {
  color: #5a64d7;
  border-bottom: 2px solid #5a64d7;
}

.tabcontent {
  display: none;
  padding: 6px 12px;
  border-top: none;
}
.no-email-wrap {
  background-image: url('./Assets/no-mail.svg');
  min-height: 13rem;
  background-position: right bottom;
  background-size: 12rem;
  background-repeat: no-repeat;
}
.note-textarea {
  border-color: transparent !important;
  resize: none;
  background-color: #fef6e1;
}
.note-textarea:focus {
  border-color: transparent !important;
  resize: none;
  outline: none;
}
.stage-toggle-wrap div:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.stage-toggle-wrap div:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.selected-stage {
  background-color: #5a64d7 !important;
}
.deal-card-icon {
  -webkit-user-drag: none;
}
.data-link-wrapper > a {
  height: 2rem;
  line-height: 1rem;
  overflow: hidden;
}
.data-link-wrapper > a:visited {
  color: blue;
}
.dashboard-card-wrapper > .dashboard-card {
  width: 32%;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-right: 1%;
}
.dashboard-card-wrapper > .dashboard-card:nth-child(3n) {
  margin-right: 0%;
}
@media (max-width: 1025px) {
  .dashboard-card-wrapper > .dashboard-card {
    width: 49%;
  }
}
@media (max-width: 650px) {
  .dashboard-card-wrapper > .dashboard-card {
    width: 98%;
    margin-bottom: 2%;
  }
}

/* Shadow */
.shadow-ms {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
}

/* Borders */
.border-m-r {
  border: 0 solid rgb(220, 220, 220);
  border-right-width: 1px;
}

.border-m-b {
  border: 0 solid rgb(220, 220, 220);
  border-bottom-width: 1px;
}

.border-s-b {
  border: 0 solid #f0f0f0;
  border-bottom-width: 1px;
}

/* Radio Button Styles */
label > input[type='radio'] {
  display: none;
}

label > input[type='radio'] + *::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.3rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: gray;
}
label > input[type='radio']:checked + *::before {
  background: radial-gradient(
    #5a64d7 0%,
    #5a64d7 40%,
    transparent 50%,
    transparent
  );
  border-color: #5a64d7;
}

.flex-shrink-0 {
  flex-shrink: 0;
}
.stage-holder:hover .stage-wrap .stage-delete-icon {
  display: block;
}
.stage-holder > .stage-wrap > .stage-delete-icon {
  display: none;
}

.sidebar-transition-overflow {
  transition: 0.5s;
  overflow: hidden;
}

.sidebar-collapsed-width {
  min-width: 60px;
}

.sidebar-expanded-width {
  width: 230px;
}

.left-menu-button:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.active-menu-button,
.left-menu-button:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.template-active {
  border: 2px solid blue;
}

.column-type-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

/* Horizontal Scroll Bar styles start */

.h-scroller {
  scrollbar-width: thin;
}

.h-scroller::-webkit-scrollbar {
  height: 0.5em;
  background-color: #f4f4f6;
}

.h-scroller::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

/* Horizontal Scroll Bar styles End */

.sidebar-arrow-icon {
  transition: transform 0.3s ease-out;
}
.rotate-180 {
  transform: rotate(180deg);
}
.rotate-360 {
  transform: rotate(360deg);
}

.bg-blue {
  background-color: var(--btn-bg-color);
}

.hide-outline {
  outline: none;
}

.left-menu-tool-tip {
  display: none;
}

.left-menu-icon:hover + .left-menu-tool-tip {
  display: inline-block;
}

.sidebar-menu-item {
  transition: display 0.3s;
}

.sidebar-toggle {
  transition: left 0.3s;
}

.sidebar:hover .expandable-button {
  visibility: visible;
}

.expandable-button {
  visibility: visible;
}

.sidebar {
  transition: width 0.3s;
}

.attrValContainer {
  // padding: 0 !important;
  padding-left: 0.375rem !important;
  padding-right: 0.375rem !important;
  padding-top: 0.125rem !important;
  padding-bottom: 0.125rem !important;
  font-size: 0.5rem !important;
  margin-left: 0.25rem;
  // width: 4px;
}

.attrContainer {
  // padding: 0 !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  padding-top: 0.175rem !important;
  padding-bottom: 0.175rem !important;
  font-size: 0.75rem !important;
  // margin-left: 0.25rem;
  // width: 4px;
}

.react-pdf {
  &__Page {
    box-shadow: 0px 0px 8px rgba(0, 3, 0, 0.5);
    margin: 10px auto 0;
  }
  &__Page:last-child {
    margin-bottom: 10px;
  }
}

.mt-auto {
  margin-top: auto !important;
}

.underline {
  text-decoration: underline;
}

.grid-action-link-h-padding {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

// overriden class from ui-library
.dkinput-value-section {
  width: 100%;
}

.compact-custom-no-input {
  justify-content: flex-end !important;
}

.custom-number-compact-input {
  &:hover {
    .disable-input-mode {
      opacity: 0.4;
    }
  }
  & > input[type='text'] {
    &:focus-visible {
      outline: none;
    }
  }
}

.show-hide-hover-icon {
  .show-icon {
    display: none;
  }
  &:hover {
    .show-icon {
      display: block;
    }
  }
}

.compliance-text-field {
  .dkinput-value-section {
    width: 60%;
  }
}

.match-header-field {
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.border-b-gray {
  border-bottom: 1px solid var(--gray-1);
}

.price-list-disable {
  opacity: 50%;
  pointer-events: none;
}

.customReport {
  .actionBtn {
    display: none;
  }
}

.customReport:hover {
  .actionBtn {
    display: flex;
  }
}

.padding-bottom-20 {
  padding-bottom: 20px !important;
}

.product-image-section:hover .product-image-delete-icon {
  opacity: 1;
}

.product-image-delete-icon {
  position: absolute;
  margin-top: 3px;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.6);
  margin-left: 75px;
}

.inventory-icon-document {
  padding-left: 0 !important;
  img.ic-s {
    width: 24px;
    height: 24px;
  }
}

.padding-bottom-50 {
  padding-bottom: 50px !important;
}

.padding-bottom-100 {
  padding-bottom: 100px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

// override padding-top
.main-holder-padding {
  padding: 24px;
  padding-top: 12px !important;
}

.tab-block-bg {
  background-color: var(--gray-3);
}

#dk-kanban-board {
  &.dk-kanban {
    user-select: none;
    // overflow: visible !important;
    scroll-behavior: smooth;

    & div.row > .dk-kanban-stage-wrapper {
      width: 250px !important;
      user-select: none !important;
      /* background: #e6e6ee; */

      & > .dk-kanban-stage-header-wrapper {
        width: 230px !important;
      }

      & > .dk-kanban-card-wrapper > .dk-kanban-card {
        transition: transform 0.15s ease;

        & > .dk-kanban-default-card {
          background-color: #ffffff;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    &.dk-kanban > .dk-kanban-stage-wrapper {
      width: 250px !important;
    }
  }
}

#dk-machine-kanban-board {
  &.dk-kanban {
    user-select: none;
    // overflow: visible !important;
    scroll-behavior: smooth;

    & div.row > .dk-kanban-stage-wrapper {
      width: 300px !important;
      user-select: none !important;
      /* background: #e6e6ee; */

      & > .dk-kanban-stage-header-wrapper {
        width: 280px !important;
      }

      & > .dk-kanban-card-wrapper > .dk-kanban-card {
        transition: transform 0.15s ease;

        & > .dk-kanban-default-card {
          background-color: #ffffff;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    &.dk-kanban > .dk-kanban-stage-wrapper {
      width: 250px !important;
    }
  }
}

.bg-chip-yellow {
  background-color: #fff6e3;
}

.border-yellow {
  border-color: #ffc64a;
  border: solid;
  border-width: 1px;
}

input.job-card-search {
  z-index: 3;
  width: 180px;
  outline: none;
  cursor: auto;
  padding-right: 10px;
  background-color: rgb(250, 250, 250);
  transition: z-index 0.8s, width 0.5s, background 0.3s ease, border 0.3s;
  border: solid;
  border-color: #dcdcdc;
  border-width: 1px;
  height: 32px;
  border-radius: 8px;
  padding: 8px 35px 8px 8px;
}

.sortable-helper {
  z-index: 10;
}

// Walkthrough tooltip CSS
.walkthrough-tooltip {
  position: fixed;
  left: 0px;
  top: 0px;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  display: inline-block;
  background-color: transparent;
}

.walkthrough-tooltip.right {
  margin-left: 5px;
}

.walkthrough-tooltip.left {
  margin-left: -5px;
}

.walkthrough-tooltip.top {
  margin-top: -5px;
}

.walkthrough-tooltip.bottom {
  margin-top: 5px;
}

.walkthrough-tooltip .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -7px;
  border-width: 7px 7px 7px 0;
  border-right-color: #000;
}

.walkthrough-tooltip.left .tooltip-arrow {
  top: 50%;
  margin-top: -7px;
  border-width: 7px 0 7px 7px;
  border-left-color: var(--books-color);
  right: -7px;
  left: auto;
}

.walkthrough-tooltip.right .tooltip-arrow {
  top: 50%;
  left: auto;
  margin-left: -7px;
  border-width: 7px 7px 7px 0;
  border-right-color: var(--books-color);
}

.walkthrough-tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -7px;
  border-width: 0 7px 7px;
  border-bottom-color: var(--books-color);
}

.walkthrough-tooltip.top .tooltip-arrow {
  top: auto;
  bottom: -7px;
  left: 50%;
  margin-left: -7px;
  border-width: 7px 7px 0;
  border-top-color: var(--books-color);
}

.walkthrough-tooltip.right .tooltip-arrow.top {
  top: 20px;
}
.walkthrough-tooltip.right .tooltip-arrow.bottom {
  top: calc(100% - 20px);
}
.walkthrough-tooltip.left .tooltip-arrow.top {
  top: 20px;
}
.walkthrough-tooltip.left .tooltip-arrow.bottom {
  top: calc(100% - 20px);
}

.walkthrough-tooltip.top .tooltip-arrow.left {
  left: 20px;
}
.walkthrough-tooltip.top .tooltip-arrow.right {
  left: calc(100% - 20px);
}
.walkthrough-tooltip.bottom .tooltip-arrow.left {
  left: 20px;
}
.walkthrough-tooltip.bottom .tooltip-arrow.right {
  left: calc(100% - 20px);
}

.walkthrough-tooltip .tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-right-color: transparent;
  border-style: solid;
}

.walkthrough-tooltip .tooltip-content {
  width: 300px;
  height: 160px;
  padding: 20px;
  color: #fff;
  text-align: center;
  background-color: var(--books-color);
  border-radius: 8px;
}

// Walkthrough tooltip CSS end

@media screen and (max-width: 1000px) {
  .dk-sidebar-toggle.expandable-button {
    visibility: visible;
  }
  .main-holder-padding {
    padding: 4%;
  }
}

.width-fill-available {
  width: -webkit-fill-available;
  width: fill-available;
  width: -moz-available;
}

.batch-tracking-grid,
.product-selection-view-popup {
  .dk-data-grid-container {
    .show-scroll-bar,
    .hide-scroll-bar {
      max-height: 400px;
      overflow: auto;
    }

    .data-grid-border {
      border: none;
    }
  }
}

.none-tracked-receive {
  .dk-data-grid-container {
    .show-scroll-bar,
    .hide-scroll-bar {
      max-height: 400px;
      overflow: auto;
    }

    .data-grid-border {
      border: none;
    }
  }
}

.tableFixHead {
  overflow-y: auto;
  max-height: 300px;
}

.tableFixHead thead {
  position: sticky;
  top: 0px;
  border: 1px solid rgb(187, 186, 186);
  z-index: 1;
}

.pr-0 {
  padding-right: 0px !important;
}

#column-0-card-0 > div,
#column-1-card-0 > div,
#column-2-card-0 > div,
#column-3-card-0 > div,
#column-4-card-0 > div {
  margin-top: 0px !important;
}

.light-gray-bg {
  background-color: rgba(248, 248, 248, 0.4) !important;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}
.image-div {
  position: relative;
}
.image-div:hover .hidden-image {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-div .hidden-image {
  position: absolute;
  display: none;
  top: 0px;
  left: 0px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.9);
}
.resize-icon {
  user-select: none;
  cursor: se-resize;
  transform: rotate(45deg);
  opacity: 0.3;
}
.user-select-none {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -ms-user-select: none;
}

.jc-date-picker-position {
  #data-grid-date-editor {
    left: -75px !important;
  }
}

.active-border {
  border-bottom: 3px solid #4962df;
  margin-bottom: -2px;
}

.text-app {
  color: #4962df;
}

.linked-records-grid-container {
  .dk-data-grid-container > .hide-scroll-bars {
    min-height: 50px;
  }
}

.invoice-data-grid {
  .dk-filter-popup {
    right: -80px;
  }
}

.automation-grid {
  .dk-filter-popup {
    right: 140px;
  }
}

/* for custom number format input */
.auto-numbering-format-input .cursor-not-allowed {
  cursor: pointer;
}

.price-book-product-dropdown {
  bottom: -36px !important;
  padding-bottom: 0 !important;
}

.bom-explosion-popup .dk-data-grid-row-bg.-active-item,
.bom-explosion-popup .listPickerBG.-active-item {
  background-color: #fafafa;
  outline: none;
}

/* for generic group component */
.group-ctrl-last {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
/* for generic group component ends */

div.row.price-list-value-change-wrapper{
  gap: 10px;
  @media screen and (max-width: 1024px) {
      flex-wrap: wrap;
  }
}

.multi-email-inp {
  [class*="-control"] {
    box-shadow: none !important;
    &:hover {border:  none !important; box-shadow: none !important;}
  }
  [class*="-control"]:hover {
    border:  none !important; box-shadow: none !important;
  }
  [class*="-IndicatorsContainer"] {
    display:  none !important;
  }
}

.price-book-version-row {
  .price-book-delete-button {
    visibility: hidden;
  }
  &:hover {
    .price-book-delete-button {
      visibility: visible;
      opacity: 0.6;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.skiptranslate > iframe { 
  height: 0 !important;
  border-style: none;
  box-shadow: none;
}