.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
}

.switch-sm {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 21px;
}

.switch input,
.switch-sm input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider,
.slider-sm {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider-sm:before {
  position: absolute;
  content: '';
  height: 15px;
  width: 15px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider,
input:checked + .slider-sm {
  background-color: var(--books-color);
}

input:focus + .slider,
input:focus + .slider-sm {
  box-shadow: 0 0 1px var(--books-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(21px);
  -ms-transform: translateX(21px);
  transform: translateX(21px);
}

input:checked + .slider-sm:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.slider.round,
.slider-sm.round {
  border-radius: 34px;
}

.slider.round:before,
.slider-sm.round:before {
  border-radius: 50%;
}
