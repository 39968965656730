.mm-width-50 {
  width: 20%;
}
.mm-fs-20 {
  font-size: 24px;
}
.mm-fs-18 {
  font-size: 28px;
}
.mm-max-width-80 {
  max-width: 40%;
}
.mm-fs-24 {
  font-size: 30px;
}
@media screen and (max-width: $mobilebp) {
  .mm-width-50 {
    width: 50%;
  }
  .mm-fs-20 {
    font-size: 20px;
  }
  .mm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .mm-fs-18 {
    font-size: 18px;
  }
  .mm-max-width-80 {
    max-width: 80%;
  }
  .mm-fs-24 {
    font-size: 24px;
  }
}
