.notes-table {
    .table {
      border-collapse: collapse;
    }
    .table,
    .table td {
      border: 1px solid rgb(187, 186, 186);
    }
    .table th {
      padding: 0.5rem;
    }
    .table thead {
      background-color: rgb(244, 244, 244);
    }
    .table th {
      position: relative;
      border: 1px solid rgb(187, 186, 186);
    }
    .resizer {
      position: absolute;
      top: 0;
      right: 0;
      width: 5px;
      cursor: col-resize;
      user-select: none;
    }
    // .resizer:hover,
    // .resizing {
    //   border-right: 2px solid blue;
    // }
  
    .resizable {
      border: 1px solid gray;
      height: 100px;
      width: 100px;
      position: relative;
    }
  }
  