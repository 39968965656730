.TemplateHolder {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.DocumentHolder {
  position: relative;
  width: 100%;
  min-height: 78.63vw;
}
