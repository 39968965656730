.flag {
  width: 24px;
  height: 14px;
  background-image: url(https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/flags_sprite_2x.png);
  background-size: 100% auto;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.54));
}

.currency-dropdown-selector {
  right: auto;
  left: 0;
  z-index: 1;
  flex-shrink: 0;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 1px;
  width: 96px;
  height: 17px;
  border-radius: 0;
  background-image: url(https://www.gstatic.com/images/icons/material/system/1x/arrow_drop_down_grey600_18dp.png);
  background-position: right center;
  background-size: 18px auto;
  background-repeat: no-repeat;
  color: rgba(0, 0, 0, 0.87);
  font-weight: inherit;
  font-size: inherit;
  line-height: unset;
  opacity: 1;
  transition: opacity 200ms;
  &[disabled='true'] {
    background-image: none;
  }
}

.currency-dropdown-selector {
  opacity: 1 !important;
}

.currency-dropdown-selector.mat-button {
  min-width: 100%;
  padding: 0;
  margin-right: 0;
  line-height: 16px;
}

.currency-dropdown-selector-flag {
  display: inline-block;
  margin-right: 0.5ex;
  margin-top: 3px;
}

.currency-select-trigger {
  display: flex;
  font-weight: 400;
  .currency-dropdown-code {
    margin-left: 10px;
    color: black;
  }
}

.flag.KYD {
  background-position: 0 0;
}

.flag.AC {
  background-position: 0 -14px;
}

.flag.AED {
  background-position: 0 -28px;
}

.flag.AFN {
  background-position: 0 -42px;
}

.flag.ALL {
  background-position: 0 -84px;
}

.flag.AMD {
  background-position: 0 -98px;
}

.flag.AOA {
  background-position: 0 -112px;
}

.flag.AQD {
  background-position: 0 -126px;
}

.flag.ARS {
  background-position: 0 -140px;
}

.flag.AUD {
  background-position: 0 -182px;
}

.flag.AWG {
  background-position: 0 -196px;
}

.flag.AZN {
  background-position: 0 -224px;
}

.flag.BAM {
  background-position: 0 -238px;
}

.flag.BBD {
  background-position: 0 -252px;
}

.flag.BDT {
  background-position: 0 -266px;
}

.flag.BGN {
  background-position: 0 -308px;
}

.flag.BHD {
  background-position: 0 -322px;
}

.flag.BIF {
  background-position: 0 -336px;
}

.flag.BMD {
  background-position: 0 -378px;
}

.flag.BND {
  background-position: 0 -392px;
}

.flag.BOB {
  background-position: 0 -406px;
}

.flag.BRL {
  background-position: 0 -434px;
}

.flag.BSD {
  background-position: 0 -448px;
}

.flag.BTN {
  background-position: 0 -462px;
}

.flag.BWP {
  background-position: 0 -490px;
}

.flag.BYN {
  background-position: 0 -504px;
}

.flag.BZD {
  background-position: 0 -518px;
}

.flag.CAD {
  background-position: 0 -532px;
}

.flag.CDF {
  background-position: 0 -560px;
}

.flag.XAF {
  background-position: 0 -574px;
}

.flag.CHF {
  background-position: 0 -602px;
}

.flag.CLP {
  background-position: 0 -644px;
}

.flag.CNY {
  background-position: 0 -672px;
}

.flag.COP {
  background-position: 0 -686px;
}

.flag.CP {
  background-position: 0 -700px;
}

.flag.CRC {
  background-position: 0 -714px;
}

.flag.CUC {
  background-position: 0 -728px;
}

.flag.CVE {
  background-position: 0 -742px;
}

.flag.ANG {
  background-position: 0 -756px;
}

.flag.CZK {
  background-position: 0 -798px;
}

.flag.DG {
  background-position: 0 -826px;
}

.flag.DJF {
  background-position: 0 -840px;
}

.flag.DKK {
  background-position: 0 -854px;
}

.flag.XCD {
  background-position: 0 -868px;
}

.flag.DOP {
  background-position: 0 -882px;
}

.flag.DZD {
  background-position: 0 -896px;
}

.flag.EA {
  background-position: 0 -910px;
}

.flag.EGP {
  background-position: 0 -952px;
}

.flag.ERN {
  background-position: 0 -980px;
}

.flag.ETB {
  background-position: 0 -1008px;
}

.flag.EUR {
  background-position: 0 -1022px;
}

.flag.FJD {
  background-position: 0 -1050px;
}

.flag.FKP {
  background-position: 0 -1064px;
}

.flag.GBP {
  background-position: 0 -1134px;
}

.flag.GEL {
  background-position: 0 -1162px;
}

.flag.GHS {
  background-position: 0 -1204px;
}

.flag.GIP {
  background-position: 0 -1218px;
}

.flag.GMD {
  background-position: 0 -1246px;
}

.flag.GNF {
  background-position: 0 -1260px;
}

.flag.GTQ {
  background-position: 0 -1330px;
}

.flag.GYD {
  background-position: 0 -1372px;
}

.flag.HKD {
  background-position: 0 -1386px;
}

.flag.HNL {
  background-position: 0 -1414px;
}

.flag.HRK {
  background-position: 0 -1428px;
}

.flag.HTG {
  background-position: 0 -1442px;
}

.flag.HUF {
  background-position: 0 -1456px;
}

.flag.IC {
  background-position: 0 -1470px;
}

.flag.IDR {
  background-position: 0 -1484px;
}

.flag.ILS {
  background-position: 0 -1512px;
}

.flag.INR {
  background-position: 0 -1540px;
}

.flag.IQD {
  background-position: 0 -1568px;
}

.flag.IRR {
  background-position: 0 -1582px;
}

.flag.ISK {
  background-position: 0 -1596px;
}

.flag.JMD {
  background-position: 0 -1638px;
}

.flag.JOD {
  background-position: 0 -1652px;
}

.flag.JPY {
  background-position: 0 -1666px;
}

.flag.KES {
  background-position: 0 -1680px;
}

.flag.KGS {
  background-position: 0 -1694px;
}

.flag.KHR {
  background-position: 0 -1708px;
}

.flag.KMF {
  background-position: 0 -1736px;
}

.flag.KPW {
  background-position: 0 -1764px;
}

.flag.KRW {
  background-position: 0 -1778px;
}

.flag.KWD {
  background-position: 0 -1792px;
}

.flag.KZT {
  background-position: 0 -1820px;
}

.flag.LAK {
  background-position: 0 -1834px;
}

.flag.LBP {
  background-position: 0 -1848px;
}

.flag.CHF {
  background-position: 0 -1876px;
}

.flag.LKR {
  background-position: 0 -1890px;
}

.flag.LRD {
  background-position: 0 -1904px;
}

.flag.LSL {
  background-position: 0 -1918px;
}

.flag.LYD {
  background-position: 0 -1974px;
}

.flag.MAD {
  background-position: 0 -1988px;
}

.flag.MDL {
  background-position: 0 -2016px;
}

.flag.MGA {
  background-position: 0 -2058px;
}

.flag.MKD {
  background-position: 0 -2086px;
}

.flag.MMK {
  background-position: 0 -2114px;
}

.flag.MNT {
  background-position: 0 -2128px;
}

.flag.MOP {
  background-position: 0 -2142px;
}

.flag.MRO {
  background-position: 0 -2184px;
}

.flag.MUR {
  background-position: 0 -2226px;
}

.flag.MVR {
  background-position: 0 -2240px;
}

.flag.MWK {
  background-position: 0 -2254px;
}

.flag.MXN {
  background-position: 0 -2268px;
}

.flag.MYR {
  background-position: 0 -2282px;
}

.flag.MZN {
  background-position: 0 -2296px;
}

.flag.NAD {
  background-position: 0 -2310px;
}

.flag.XPF {
  background-position: 0 -2324px;
}

.flag.NGN {
  background-position: 0 -2366px;
}

.flag.NIO {
  background-position: 0 -2380px;
}

.flag.NOK {
  background-position: 0 -2408px;
}

.flag.NPR {
  background-position: 0 -2422px;
}

.flag.NZD {
  background-position: 0 -2464px;
}

.flag.OMR {
  background-position: 0 -2478px;
}

.flag.PAB {
  background-position: 0 -2492px;
}

.flag.PEN {
  background-position: 0 -2506px;
}

.flag.XPF {
  background-position: 0 -2520px;
}

.flag.PGK {
  background-position: 0 -2534px;
}

.flag.PHP {
  background-position: 0 -2548px;
}

.flag.PKR {
  background-position: 0 -2562px;
}

.flag.PLN {
  background-position: 0 -2576px;
}

.flag.PW {
  background-position: 0 -2660px;
}

.flag.PYG {
  background-position: 0 -2674px;
}

.flag.QAR {
  background-position: 0 -2688px;
}

.flag.RON {
  background-position: 0 -2716px;
}

.flag.RSD {
  background-position: 0 -2730px;
}

.flag.RUB {
  background-position: 0 -2744px;
}

.flag.RWF {
  background-position: 0 -2758px;
}

.flag.SAR {
  background-position: 0 -2772px;
}

.flag.SBD {
  background-position: 0 -2786px;
}

.flag.SCR {
  background-position: 0 -2800px;
}

.flag.SDG {
  background-position: 0 -2814px;
}

.flag.SEK {
  background-position: 0 -2828px;
}

.flag.SGD {
  background-position: 0 -2842px;
}

.flag.SHP {
  background-position: 0 -2856px;
}

.flag.SLL {
  background-position: 0 -2912px;
}

.flag.SOS {
  background-position: 0 -2954px;
}

.flag.SRD {
  background-position: 0 -2968px;
}

.flag.SSP {
  background-position: 0 -2982px;
}

.flag.STD {
  background-position: 0 -2996px;
}

.flag.ANG {
  background-position: 0 -3024px;
}

.flag.SYP {
  background-position: 0 -3038px;
}

.flag.SZL {
  background-position: 0 -3052px;
}

.flag.TA {
  background-position: 0 -3066px;
}

.flag.USD {
  background-position: 0 -3080px;
}

.flag.THB {
  background-position: 0 -3136px;
}

.flag.TJS {
  background-position: 0 -3150px;
}

.flag.USD {
  background-position: 0 -3178px;
}

.flag.TMT {
  background-position: 0 -3192px;
}

.flag.TND {
  background-position: 0 -3206px;
}

.flag.TOP {
  background-position: 0 -3220px;
}

.flag.TRY {
  background-position: 0 -3234px;
}

.flag.TTD {
  background-position: 0 -3248px;
}

.flag.TWD {
  background-position: 0 -3276px;
}

.flag.TZS {
  background-position: 0 -3290px;
}

.flag.UAH {
  background-position: 0 -3304px;
}

.flag.UGX {
  background-position: 0 -3318px;
}

.flag.UN {
  background-position: 0 -3346px;
}

.flag.USD {
  background-position: 0 -3360px;
}

.flag.UYU {
  background-position: 0 -3374px;
}

.flag.UZS {
  background-position: 0 -3388px;
}

.flag.VEF {
  background-position: 0 -3430px;
}

.flag.VND {
  background-position: 0 -3472px;
}

.flag.VUV {
  background-position: 0 -3486px;
}

.flag.XPF {
  background-position: 0 -3500px;
}

.flag.WST {
  background-position: 0 -3514px;
}

.flag.YER {
  background-position: 0 -3542px;
}

.flag.ZAR {
  background-position: 0 -3570px;
}

.flag.ZMW {
  background-position: 0 -3584px;
}

.flag.BWP {
  background-position: 0 -3598px;
}
