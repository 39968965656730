.ql-toolbar.ql-snow {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  // height: 45px;
  border-width: 2px 0;
  border-color: #f2f2f2;
  // overflow-x: auto;
}

.ql-container.ql-snow {
  border-width: 0 0 2px;
  border-color: #f2f2f2;
}

/* Scroll Bar styles start */

.ql-editor {
  overflow-y: auto;
  max-height: 300;

  scrollbar-width: thin;
}

.ql-toolbar.ql-snow::-webkit-scrollbar,
.ql-editor::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f4f4f6;
}

.ql-toolbar.ql-snow::-webkit-scrollbar-thumb,
.ql-editor::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #ccc;
}

/* Horizontal Scroll Bar styles End */

.ql-formats {
  flex-shrink: 0;
}

/* Font size updates start */
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "Small";
  font-size: 10px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="13px"]::before {
  content: "Normal";
  font-size: 13px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "Medium";
  font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "Large";
  font-size: 18px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "Huge";
  font-size: 24px !important;
}

.ql-picker-label.ql-active[data-value="13px"]::before {
  content: "Normal" !important;
}
.ql-picker-label.ql-active[data-value="10px"]::before {
  content: "Small" !important;
}
.ql-picker-label.ql-active[data-value="16px"]::before {
  content: "Medium" !important;
}
.ql-picker-label.ql-active[data-value="18px"]::before {
  content: "Large" !important;
}
.ql-picker-label.ql-active[data-value="24px"]::before {
  content: "Huge" !important;
}
/* Font size updates end */

/* Styles for Placeholders in editor and placeholder format button in toolbar */
.custom_email_placeholder {
  padding: 0px 3px;
  /* border: 1px solid #ccc; */
  border-radius: 2px;
  background: rgb(210, 228, 249);
}

.ql-picker.ql-email-placeholder .ql-picker-label::before {
  padding-right: 16px;
  content: "Insert Placeholder";
}
.ql-picker.ql-email-placeholder .ql-picker-options {
  overflow: auto;
  max-height: 200px;
  width:210px;

}

.ql-picker.ql-email-placeholder .ql-picker-label.ql-active::before {
  content: attr(data-title);
}

/* hiding outline when any dropdown from toolbar opens */
.ql-formats .ql-picker-label[aria-expanded="true"] {
  outline: none;
}

// .ql-email-placeholder {
//     display: none !important;
// }


#operation-editor-body, #jobCard-editor-body {
  height: 190px !important;
}