@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'react-date-range/dist/styles.css'; // main style file
@import 'react-date-range/dist/theme/default.css'; // theme css file

:root {
  --books-color: rgb(56, 79, 190);
  --books-text-color: #2d2b25;
  --main-color-hover: rgb(236, 237, 250);
  --btn-bg-color: rgb(73, 98, 223);
  --btn-bg-color-transparent: rgba(73, 98, 223, 0.1);
  --gray-1: #dcdcdc;
  --gray-2: #f4f4f6;
  --gray-3: #fafafa;
}

body {
  margin: 0;
  font-family: Ubuntu, ui-sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI,
    Helvetica, Apple Color Emoji, Arial, sans-serif, Segoe UI Emoji,
    Segoe UI Symbol;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
  color: var(--books-text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer base {
  img,
  video {
    max-width: unset;
  }
  img {
    box-sizing: content-box;
  }
}

@layer utilities {
  .number-hide-arrows {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
.type-number-arrow-none::-webkit-outer-spin-button,
.type-number-arrow-none::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.type-number-arrow-none[type='number'] {
  -moz-appearance: textfield;
}
.barcode-form-field {
  width: 49% !important;
}
.dk-tab-panel-active-tab {
  border-bottom: 2px solid var(--books-color);
  margin-bottom: -2px;
}
.dk-tab {
  .close-icon {
    visibility: hidden;
  }
  &:hover {
    .close-icon {
      visibility: visible;
    }
  }
}

div.popup-window.draggable-popup {
  position: absolute !important;
  transform: none !important;
  left: auto;
  top: auto;
  padding: 0px;
}
div.transparent-background.popup-parent{
  display: flex;
  align-items: center;
  justify-content: center;
}
div.popup-window.draggable-popup div.pop-header-drag-handle {
  cursor: grab;
}
.datetime-title.mt-l.mb-s{
  margin-top: 0px;
  margin-bottom:0px;
}

/** GOOGLE LANGUAGE SELECTOR STYLES START ******* */
#goog-gt-tt {
  display: none !important;
}

#google_translate_element .goog-te-combo {
  margin: 0;
  padding: 8px 4px;
  border-radius: 6px;
  outline: none;
}

.VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q {
  background: transparent !important;
  box-shadow: unset !important;
}

/** GOOGLE LANGUAGE SELECTOR STYLES END ********* */

@import './styles/variables.scss';
@import './styles/utility.scss';
@import './styles/components/dashboard.scss';
@import './styles/components/money.scss';
@import './styles/components/payment.scss';
@import './styles/components/print.scss';