.draggableDiv {
    position: absolute;
    /* border: 1px solid gray; */
    /* border-radius: 10px; */
    /* color: black; */
}

.draggableBorderDiv {
    position: absolute;
    /*padding: 1px 5px 1px 1px;*/
    /*width: 10;*/
    /*border: 2px solid #3773e1;*/
    border-radius: 2px;
    color: black;
}

docImg:before {
    position: absolute;
    left: 10px;
    display: block;
    align-self: center;
    border-style: none;
    background-color: white;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=);
    content: ' ';

}
