.pay-web-width-270 {
  width: 270px;
}
.pay-web-width-32 {
  width: 32%;
}
.pay-web-ml-l {
  margin-left: 18px;
}

@media screen and (max-width: $mobilebp) {
  .pay-web-width-270 {
    width: 100%;
  }
  .pay-web-width-32 {
    width: 100%;
  }
  .pay-web-ml-l {
    margin-left: 0px;
  }
}
