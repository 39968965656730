.mrp_bg_red {
  background-color: rgb(227, 80, 95);
}

.mrp_bg_green {
  background-color: rgb(8, 197, 105);
}

.mrp_bg_green1 {
  background-color: rgba(0, 205, 8, 0.4);
}

.mrp_bg_yellow {
  background-color: rgb(255, 208, 0);
}

.mrp_bg_orange {
  background-color: rgb(255, 164, 4);
}

.mrp_bg_blue {
  background-color: rgb(95, 164, 252);
}

.mrp_bg_gray {
  background-color: rgb(171, 170, 166);
}

.mrp_bg_gray1 {
  background-color: rgb(125, 125, 124);
}

.mrp_bg_purple2 {
  background-color: rgb(158, 90, 235);
}

.mrp_bg_blue_opacity {
  background-color: rgb(95, 164, 252, 0.75);
  border: 1px solid rgb(95, 164, 252);
}

.mrp_bg_orange_opacity {
  background-color: rgb(255, 164, 4, 0.75);
  border: 1px solid rgb(255, 164, 4);
}

.mrp_bg_red_opacity {
  background-color: rgb(227, 80, 95, 0.75);
  border: 1px solid rgb(227, 80, 95);
}

.mrp_bg_gray_opacity,
.bg-gray_opacity {
  background-color: rgb(171, 170, 166, 0.75);
  border: 1px solid rgb(171, 170, 166);
}

.mrp_bg_green_opacity {
  background-color: rgb(8, 197, 105, 0.75);
  border: 1px solid rgb(8, 197, 105);
}

.mrp_bg_purple_opacity {
  background-color: #ab95ebf5;
  border: 1px solid #7e55f0;
}

.mrp_bg_purple2_opacity {
  background-color: #ab95ebf5;
  border: 1px solid #7e55f0;
}

.mrp_bg_sea_green_opacity {
  background-color: rgb(15, 165, 165, 0.75);
  border: 1px solid rgba(15, 165, 165, 0.9);
}

.qc-template-container {
  .dk-data-grid-container {
    padding-bottom: 0px;

    .mt-r.mb-r.row-reverse {
      margin: 0px;
    }
  }
}

.holiday-grid-data {
  .dk-data-grid-container {
    max-height: 320px;

    .data-grid-border {
      border: none;
      box-shadow: none;
    }
  }
}

.bom-explosion {
  line-height: normal !important;
}

.batch-chip-css {
  padding: 1px 5px 1px 5px;
}

@media screen and (max-device-width: 1024px) {
  .mrp-db-graph-wrap {
    flex-wrap: wrap;

    .mrp-db-graph-wrap {
      flex-direction: row;
      width: 100% !important;
      flex-wrap: wrap;

      .column {
        width: 100%;
      }
    }

    .mrp-operation-grid-wrap {
      width: 100% !important;
    }
  }
}

.attachment-row {
  .attachment-delete-btn {
    display: none;
  }

  &:hover {
    .attachment-delete-btn {
      display: block;
    }
  }
}

.job-card-filter-container {
  .dk-filter-popup {
    right: 0px;
    top: 8px !important;
  }
}

.wo-job-card-grid-dependency-wrapper {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90px;
}
.padding-button-custom {
  padding-left: 7px !important;
  padding-right: 7px !important;
  padding-top: 0px;
  padding-bottom: 0px;
}
