.react-select-container {
  width: 200px;
}

.invoice-table {
  .table {
    border-collapse: collapse;
  }
  .table,
  .table td {
    border: 1px solid rgb(187, 186, 186);
  }
  .table th {
    padding: 0.5rem;
  }
  .table thead {
    background-color: rgb(244, 244, 244);
  }
  .table th {
    position: relative;
    border: 1px solid rgb(187, 186, 186);
  }
  .resizer {
    position: absolute;
    top: 0;
    right: 0;
    width: 5px;
    cursor: col-resize;
    user-select: none;
  }
  // .resizer:hover,
  // .resizing {
  //   border-right: 2px solid blue;
  // }

  .resizable {
    border: 1px solid gray;
    height: 100px;
    width: 100px;
    position: relative;
  }
}

.document-table {
  input,
  textarea,
  [contenteditable='true'] {
    &:hover,
    &:active,
    &:focus,
    &:focus-visible,
    &:focus-within {
      outline: none;
      border: 1px solid var(--gray-1);
    }
    &:disabled {
      background-color: var(--gray-2);
    }
  }

  input.has-error,
  textarea.has-error,
  [contenteditable='true'].has-error {
    border: 1px solid red;
    &:hover,
    &:active,
    &:focus,
    &:focus-visible,
    &:focus-within {
      border: 1px solid red;
    }
    &:disabled {
      background-color: var(--gray-2);
    }
  }
}

.input-height {
  height: 28px;
}

.bg-gray0,
.bg-gray0 .bg-gray1 {
  background-color: rgb(250, 250, 250);
}

.hide-title-section > div {
  &:first-child {
    display: none;
  }

  margin: 0;
}

.custom-field-input {
  + .custom-field-control {
    display: none;
  }

  &:hover + .custom-field-control,
  + .custom-field-control:hover {
    display: flex;
  }
}

.parent-block {
  .child-block {
    display: none;
  }
  &:hover {
    .child-block {
      display: block;
    }
  }
}

.padding-top-2px {
  padding-top: 2px;
}
