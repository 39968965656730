.step2-card {
  position: relative;
  width: 525px;
  min-height: 118px;
  box-shadow: 0px 4px 12px #00000029;
  border-radius: 8px;
  border: 2px solid #f1f5ff;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 25px 15px;
  margin-bottom: 5px;

  & .icon {
    margin-right: 25px;
    width: 54px;
    height: 54px;
  }
}
