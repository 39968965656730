.doc-build-alert {
    position: fixed;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 6px;
    padding: 18px;
    left: 50%;
    top: 45%;
    font-size: 14px;
    width: 250px;
    z-index: 9999;
    text-align: left;
    box-shadow: 0px 0px 12px rgba(0, 0, 20, 0.18);
}

.doc-build-alert-background {
    position: fixed;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.4);
}

.doc-build-alert-title {
    font-weight: bold;
    font-size: 15px;
}
.doc-build-alert-message {
    margin-top: 10px;
}

.doc-build-alert-textField {
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    border: 2px solid lightgrey;
    padding: 5px;
    border-radius: 5px;
    background-color: #f8f8f8;
}
.doc-build-alert-button-row {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    justify-content: flex-end;
}

.doc-build-alert-button {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
}
