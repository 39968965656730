.grid-cols-2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid {
    display: grid;
}

.gap-1{
    gap: 1rem;     
}
