.onboarding-card {
  width: 60%;
  box-shadow: 0px 4px 12px #00000029;
  border-radius: 8px;
  border: 2px solid #f1f5ff;
  box-sizing: border-box;
  padding: 36px;
  margin-bottom: 25px;
  cursor: pointer;

  &:hover {
    border-color: var(--books-color);
    background: #f5f7fd;
  }
}
