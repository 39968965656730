.draggableDiv {
    position: absolute;
    /* padding: 5px 10px 5px 10px; */
    width: 10;
    /* border: 1px solid gray; */
    /* border-radius: 10px; */
    color: black;
    white-space: pre-wrap;
}

.draggableBorderDiv {
    position: absolute;
    /* padding: 0px 4px 0px 0px; */
    /*width: 10;*/
    /* border: 2px solid #3773e1; */
    border-radius: 10px;
    background-color: white;
    color: black;
    white-space: pre-wrap;
}

.draggableTextInput {
    overflow: auto;
    height: auto;
    outline: none;
    outline: none;
    border: none;
    border: none;
    background-color: white;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    white-space: pre-wrap;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.draggableTextInput:focus {
    outline: none;
    outline: none;
    border: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}
