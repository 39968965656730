.fw-b {
  font-weight: bold;
}

.text-black-light {
  color: rgb(27, 26, 26);
}

.text-align-center {
  text-align: center;
}

.align-items-center {
  align-items: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.mt-l {
  margin-top: 18px;
}

.row {
  flex-direction: row;
  display: flex;
}

.row-reverse {
  flex-direction: row-reverse;
  display: flex;
}

.calendarHeaderDiv {
  display: flex;
  flex-direction: row;
}

.optionImage {
  width: 20px;
  height: 20px;
}

.dayDiv {
  display: flex;
  align-self: flex-start;
  flex-flow: row wrap;
  width: 100%;
  height: 100%;
  text-align: center;
}

.white-space-nowrap {
  white-space: nowrap;
}

.dayItemDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: none;
  font-weight: 500;
  color: gray;
  margin-bottom: 6px;
}

.unselectedDateItemDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: fill;
  color: gray;
  font-weight: 500;
  cursor: pointer;
}

.selectedDateItemDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: fill;
  font-weight: 600;
  cursor: pointer;
}

.monthDiv {
  align-self: center;
  text-align: left;
  font-size: 12px;
}

@media only screen and (max-width: 1000px) {
  .row-responsive {
    flex-wrap: wrap;
  }
  .display-only-web {
    display: none;
  }
  .display-only-mobile {
    display: flex;
  }
}

.height_60{
  height: 60px
}

.height_150{
  height: 150px;
}

.height_90 {
  height: 90px;
}