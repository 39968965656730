.RowDiv {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
.RowReverseDiv {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  width: 100%;
}
.ColumnDiv {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.EditableLabel {
  font-size: 12px;
  color: black;
  /*margin-top: 15px;*/
  /*margin-bottom: 5px;*/
  /*margin-left: 3px;*/
  font-weight: 600;
  width: 90%;
  border: none;
  background-color: transparent;
}

.EditableLabelTableColumn {
  font-size: 12px;
  color: black;
  /*margin-top: 15px;*/
  /*margin-bottom: 5px;*/
  /*margin-left: 3px;*/
  font-weight: 700;
  /*width: 90%;*/
  border: none
}
/*.EditableLabelTableColumn:focus{*/
/*  outline-width: 0;*/
/*}*/
.TextField {
  /*margin-bottom: 10px;*/
  padding: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  box-sizing: border-box;
  /* box-shadow: 0 0 2px rgba(0, 0, 0, 0.3); */
  outline-width: 0;
  outline-style: solid;
  border: solid;
  border-width: 0px;
  border-color: rgb(230, 230, 230);
  border-radius: 0px;
  background-color: white;
  font-size: 12px;
  color: rgb(60, 60, 60);
}
.VerticalScrollEnabled {
  overflow-y: scroll;
}
.ListPicker {
  outline: none;
  color: black;
  appearance: none;
}
.ColorPicker {
  padding: 3px;
  padding-top: 1px;
  padding-bottom: 1px;
  border: solid;
  border-width: 1px;
  border-color: rgb(200, 200, 200);
  border-radius: 6px;
}

.ListPickerHolderStyle {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  background-color: rgb(245, 245, 245);
  border-radius: 6px;
  padding: 0px;
  box-sizing: border-box;
  margin-top: 20px;
  border: solid;
  border-width: 1.5px;
  border-color: rgb(230, 230, 230);
  overflow: hidden;
}
.ListPickerArrowStyle {
  position: absolute;
  right: 13px;
  bottom: 11px;
  width: 8px;
  opacity: 0.8;
}
.HeaderRow {
  justify-content: space-between;
  padding: 6px 8px;
  box-sizing: border-box;
  align-items: center;
}
.HeaderButton {
  cursor: pointer;
  font-size: 11px;
  color: rgb(50, 50, 50);
  background-color: rgb(230, 230, 230);
  padding: 3px;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 8px;
}
.DownArrow {
  width: 0;
  height: 0;
  border-right: 12px solid transparent;
  border-bottom: 12px solid white;
  border-left: 12px solid transparent;
}
.WindowPopup {
  position: absolute;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  overflow: scroll;
  padding: 20px;
  width: 80%;
  height: 80%;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  transform: translate(-50%, -50%);

  scrollbar-width: none;
  -ms-overflow-style: none;
}
.WindowPopup::-webkit-scrollbar {
  display: none;
}

.PageHolder {
  /*box-sizing: border-box;*/
  /* margin-top: 3vw;
  margin-bottom: 3vw; */
  border-radius: 6px;
  background-color: white;
  /*overflow: hidden;*/
}
.DropShadow {
  box-shadow: 10px 2px 10px 2px rgba(0, 0, 0, 0.05);
}
.Shadow {
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
}
.ShadowMedium {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
}
.ShadowSmall {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
}

.Doc-Rotate {
  direction: rtl;
  white-space: nowrap;
  transform: rotate(270deg);
}

.Doc-Line {
  width:100%;
  height: 1px;
  background-color: rgb(235, 235, 235);
}

.popupActionButton {
  margin-left: 10px;
  padding: 5px 10px;
  height: 30px;
  outline: none;
  border: 1px none rgb(55, 115, 225);
  border-radius: 8px;
  background-color: rgb(55, 115, 225);
  color: white;
  font-weight: 600;
  cursor: pointer;
}

.nav-link{
  color: #3773e1;
  text-decoration: none;
}
