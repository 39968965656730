.web-wm-ml-r {
  margin-left: 8px;
}
.web-wm-mr-r {
  margin-right: 0;
}

@media screen and (max-width: $mobilebp) {
  .web-wm-ml-r {
    margin-left: 18px;
  }
  .web-wm-mr-r {
    margin-right: 18px;
  }
}
