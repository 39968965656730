.setting-selected {
  background-color: var(--btn-bg-color-transparent);
  &::before {
    content: "";
    position: absolute;
    display: inline-block;
    background-color: var(--btn-bg-color);
    width: 3px;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.show-children {
  display: flex;
}

.hide-children {
  display: none;
}

.display-only-web.settings-search-bar {
  display: flex;
  .textField {
    font-size: 13px;
  }
}

.new-settings-wrapper {
  .textField {
    font-size: 13px;
  }
}