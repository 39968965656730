.mobile-full-grid-width {
  width: 50%;
}

.mobile-full-grid-width-2 {
  width: 24%;
}
.web-pr-10 {
  padding-right: 2.5rem;
}
.web-width-70 {
  width: 70%;
}
.web-padding-s {
  padding: 8px;
}
.web-width-400 {
  width: 400px;
}
.mobile-align-items-start {
  align-items: flex-end !important;
}
.web-ml-r {
  margin-left: 8px;
}
.mobile-ml-r {
  margin-left: 0px;
}
.web-mr-r {
  margin-right: 12px;
}
.web-grid-width {
  width: 49.5%;
}
.mobile-mt-m {
  margin-top: 0;
}
.mobile-mb-xs {
  margin-bottom: 0;
}
.web-width-48 {
  width: 48%;
}
.mobile-mt-l {
  margin-top: 0;
}
.web-ml-l {
  margin-left: 12px;
}
.mobile-mt-s {
  margin-top: 0;
}
.web-parent-width {
  width: 100%;
}
.web-padding-s {
  padding: 8px;
}
.web-padding-m {
  padding: 16px;
}
.mobile-min-cell-width {
  min-width: auto;
}
.hide-chat-bubble {
  #deskera-chat-root {
    display: none;
  }
}
.web-field-width {
  min-width: 7rem;
  max-width: 7rem;
}
.mobile-width-80 {
  width: 50%;
}
.web-mr-l {
  margin-right: 18px;
}
.mob-p-h-xl {
  padding-left: 24px;
  padding-right: 24px;
}

.dk-data-grid-row-bg {
  .action-wrapper {
    display: none;
  }

  &:hover .action-wrapper {
    display: inline-block;
  }
}

.hover-actions-container {
  .hover-action-wrapper {
    display: none;
  }

  &:hover .hover-action-wrapper {
    display: inline-block;
  }
}

.break-text {
  word-break: break-all;
}

@media screen and (max-width: $mobilebp) {
  .mobile-mb {
    margin-bottom: 16px;
  }
  .mobile-full-grid-width {
    width: 100%;
  }
  .mobile-full-grid-width-2 {
    width: 100%;
  }
  .mobile-mb-s {
    margin-bottom: 8px;
  }
  .mobile-mb-xs {
    margin-bottom: 4px;
  }
  .dk-data-grid-container
    > .row.justify-content-between
    > div
    > div.row-reverse.position-relative {
    flex-wrap: wrap-reverse;
  }
  .mobile-scroll-x {
    overflow-x: scroll;
  }
  .mobile-scroll-hidden {
    overflow: hidden;
  }
  .mobile-pb {
    padding-bottom: 16px;
  }
  .web-pr-10 {
    padding-right: 0;
  }
  .web-width-70 {
    width: 100%;
  }
  .mobile-mt-s {
    margin-top: 4px;
  }
  .mobile-mt-m {
    margin-top: 12px;
  }
  .web-padding-s {
    padding: 0;
  }
  .web-width-400 {
    width: 100%;
  }
  .mobile-align-items-start {
    align-items: flex-start !important;
  }
  .web-ml-r {
    margin-left: 0;
  }
  .mobile-ml-r {
    margin-left: 8px;
  }
  .web-mr-r {
    margin-right: 0;
  }
  .web-grid-width {
    width: 100%;
  }
  .web-width-48 {
    width: 100%;
  }
  .mobile-mt-l {
    margin-top: 18px;
  }
  .web-ml-l {
    margin-left: 0;
  }
  .mobile-sidebar-pb {
    padding-bottom: 64px;
  }
  .web-parent-width {
    width: auto;
  }
  .mobile-min-cell-width {
    min-width: 160px;
  }
  .web-padding-m {
    padding: 0;
  }
  .mobile-width-80 {
    width: 80%;
  }
  .web-mr-l {
    margin-right: 0;
  }
  .mob-p-h-xl {
    padding-left: 4%;
    padding-right: 4%;
  }
}

@media screen and (max-width: $largebp) and (min-width: $mobilebp) {
  .dk-sidebar {
    position: absolute;
    top: 0;
    left: 0;
  }
  .dk-sidebar-toggle {
    display: block !important;
  }
  .dk-sidebar-toggle.collapsible-button {
    visibility: visible;
  }
  .display-only-mobile {
    display: flex !important;
  }
  .dk-sidebar-toggle.expandable-button {
    visibility: visible;
  }
  .mobile-full-grid-width-2 {
    width: 31%;
  }
}
