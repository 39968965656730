.listPicker {
    padding: 10px;
    width: 99%;
    outline: none;
    border-color: #e6e6e6;
    border-radius: 8px;
    color: #000;

    appearance: none;
}


.listPickerHolderStyle {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}

.listPickerArrowStyle {
    position: absolute;
    right: 13px;
    bottom: 14px;
    width: 8px;
    opacity: 0.8;
}

.imageRatioButton {
    display: inline;
}

.imageRatioText {
    padding-right: 10px;
    padding-left: 10px;
}

.imageResizeDiv {
    display: flex;
}

.bannerImageButton {
    margin-right: 10px;
    padding: 4px 10px;
    outline: none;
    border: 1px solid rgb(230, 230, 230);
    border-radius: 4px;
    background-color: rgb(245, 245, 245);
    color: black;
    font-size: 15px;
    cursor: pointer;
}

.docTextField {
    margin-bottom: 10px;
    padding: 10px;
    padding-top: 13px;
    padding-bottom: 13px;
    width: 100%;
    /* box-shadow: 0 0 2px rgba(0, 0, 0, 0.3); */
    outline-width: 0;
    outline-style: solid;
    border: solid;
    border-width: 1px;
    border-color: rgb(230, 230, 230);
    border-radius: 8px;
    background-color: white;
    font-size: 13px;
}